<template>
  <div>
    <asterix-section :status="sectionStatus">
      <template #header-right>
        <div>
          <sun-button
            variant="pill"
            class="px-4 text-xs bg-gray-700 custom-p-1 hover:bg-gray-800"
            color="gray"
            @click="goToNewCampaign"
          >
            + New Campaign
          </sun-button>
        </div>
      </template>

      <template #content>
        <sun-filter-layout
          id="filter-box"
          :filters-added="filterFind"
          :filters-available="availableFilters"
          :has-error="anyError"
          show-reset
          @change="filtersSetFiltersFind"
          @close="filtersResetErrors"
          @remove="filtersResetErrors"
          @reset="resetFilters"
        >
          <template #quickFilters>
            <sun-search-input
              :value="filterQuick.search"
              class="mr-2"
              class-input="text-sm"
              placeholder="Search..."
              @search="onQuickFiltersSearch($event, 'search')"
            />
          </template>
          <!--Filters Slot :Start -->
          <template #[`filter.name`]="{ filter, onSelect, isDuplicate }">
            <sun-input
              type="text"
              :text-error="filtersGetFilterError(filter, isDuplicate)"
              :error="!!filtersGetFilterError(filter, isDuplicate)"
              @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
              @enter="filtersOnSelectFilter(filter, $event.value, onSelect)"
            />
          </template>
          <template #[`filter.number`]="{ filter, onSelect, isDuplicate }">
            <sun-input
              type="text"
              :text-error="filtersGetFilterError(filter, isDuplicate)"
              :error="!!filtersGetFilterError(filter, isDuplicate)"
              @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
              @enter="filtersOnSelectFilter(filter, $event.value, onSelect)"
            />
          </template>
        </sun-filter-layout>

        <sun-data-table
          :headers="headers"
          :content="items"
          hoverable
          striped
          :loading="isLoading"
          class="w-full"
          @sort="onSortTable"
        >
          <template #[`col.name`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass" :title="item.name">
              <asterix-column-router-link :item="item" :route="campaignEdit" param-value="id" param-name="campaignId" />
            </sun-data-table-cell>
          </template>
          <template #[`col.number`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              {{ item.campaignId }}
            </sun-data-table-cell>
          </template>

          <template #[`col.status`]="{ item }">
            <sun-data-table-cell>
              <status-pill :status="item.status.value" :color="item.status.color" />
            </sun-data-table-cell>
          </template>

          <template #[`col.createdAt`]="{ item }">
            <sun-data-table-cell>
              <date-tooltip :date="item.createdAt" class="inline-flex" />
            </sun-data-table-cell>
          </template>

          <template #[`col.actions`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              <table-action-menu :actions="actions" :item="item" :items="items" @click="onActionClick($event, item)" />
            </sun-data-table-cell>
          </template>

          <template #empty>
            <asterix-no-data class="bg-white" />
          </template>
        </sun-data-table>

        <sun-pagination-page
          :key="currentPage"
          :total-pages="totalPages"
          :total-items="totalItems"
          :current-page="currentPage"
          :value="itemsPerPage"
          class="my-6"
          @changePage="goToPage({ page: $event })"
          @changePerPage="changeItemsPerPage"
        />
      </template>
    </asterix-section>

    <delete-modal :open="showModal" @cancel="closeDeleteModal" @confirm="deleteElement">
      <template #description> {{ deleteModalDescription }} </template>
    </delete-modal>
  </div>
</template>

<script>
import { deleteModalMixin } from '@/mixins/delete/deleteModal';
import filtersMixin from '@/mixins/filters/filtersMixin';
import { indexMixin } from '@/mixins/index/indexMixin';
import { CAMPAIGNS_RESOURCE } from '@/services/keys';
import { EcommerceApi } from '@/services/api/EcommerceApi';
import { getCampaigns } from '@/services/modules/ecommerce/campaign';
import { activeClientMixin } from '@/mixins/common/activeClientMixin';
import AsterixSection from '@/components/templates/AsterixSection';
import { create } from '@/router/private/modules/ecommerce/demand/campaign/create';
import { edit as campaignEdit } from '@/router/private/modules/ecommerce/demand/campaign/edit';
import CONFIG from './config';
import DeleteModal from '@/components/organisms/shared/DeleteModal';
import AsterixColumnRouterLink from '@/components/atoms/AsterixColumnRouterLink';

export default {
  name: 'CampaignList',
  components: {
    AsterixSection,
    AsterixColumnRouterLink,
    TableActionMenu: () => import('@/components/organisms/shared/TableActionMenu'),
    StatusPill: () => import('@/components/atoms/StatusPill'),
    DateTooltip: () => import('@/components/atoms/DateTooltip/DateTooltip'),
    AsterixNoData: () => import('@/components/organisms/shared/AsterixNoData'),
    DeleteModal,
  },
  mixins: [
    activeClientMixin,
    deleteModalMixin,
    filtersMixin({
      filters: CONFIG.filters,
      filterQuick: { search: undefined },
    }),
    indexMixin,
  ],
  data: () => ({
    headers: CONFIG.columns,
    actions: [{ name: 'Edit' }, { name: 'Delete' }],
    items: [],
    currentApi: new EcommerceApi(),
    resource: CAMPAIGNS_RESOURCE,
    deleteModalDescription: '',
    campaignEdit,
  }),
  created() {
    this.filterDefault = [{ name: 'client.id', value: this.activeClient?.id }];
  },
  async mounted() {
    // load filters before api request
    await this.filtersLoadAllfiltersOnMounted();
    await this.getTableItems();
  },
  methods: {
    onActionClick(event, item) {
      switch (event.name) {
        case 'Edit':
          this.goToShowCampaign(item?.id);
          break;
        case 'Delete':
          this.routeToDeleteCampaign(item);
          break;
      }
    },
    closeDeleteModal() {
      this.selectedId = null;
      this.showModal = false;
    },
    deleteElement() {
      this.deleteElementById();
    },
    async getTableItems() {
      this.isLoading = true;
      const { data, isCancel } = await this.getItemsFromAPI(getCampaigns);
      this.items = data;
      if (!isCancel) {
        this.isLoading = false;
      }
    },
    goToNewCampaign() {
      this.$router.push(create);
    },
    goToShowCampaign(id) {
      this.$router.push({ name: campaignEdit.name, params: { campaignId: id } });
    },
    routeToDeleteCampaign(item) {
      this.deleteModalDescription = `Campaign "${item.name}" is going to be deleted.`;
      this.showModal = true;
      this.selectedId = item.id;
      this.openModal({ id: item.id });
    },
  },
};
</script>
