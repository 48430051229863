var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("asterix-section", {
        attrs: { status: _vm.sectionStatus },
        scopedSlots: _vm._u([
          {
            key: "header-right",
            fn: function () {
              return [
                _c(
                  "div",
                  [
                    _c(
                      "sun-button",
                      {
                        staticClass:
                          "px-4 text-xs bg-gray-700 custom-p-1 hover:bg-gray-800",
                        attrs: { variant: "pill", color: "gray" },
                        on: { click: _vm.goToNewCampaign },
                      },
                      [_vm._v(" + New Campaign ")]
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _c("sun-filter-layout", {
                  attrs: {
                    id: "filter-box",
                    "filters-added": _vm.filterFind,
                    "filters-available": _vm.availableFilters,
                    "has-error": _vm.anyError,
                    "show-reset": "",
                  },
                  on: {
                    change: _vm.filtersSetFiltersFind,
                    close: _vm.filtersResetErrors,
                    remove: _vm.filtersResetErrors,
                    reset: _vm.resetFilters,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "quickFilters",
                        fn: function () {
                          return [
                            _c("sun-search-input", {
                              staticClass: "mr-2",
                              attrs: {
                                value: _vm.filterQuick.search,
                                "class-input": "text-sm",
                                placeholder: "Search...",
                              },
                              on: {
                                search: function ($event) {
                                  return _vm.onQuickFiltersSearch(
                                    $event,
                                    "search"
                                  )
                                },
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: `filter.name`,
                        fn: function ({ filter, onSelect, isDuplicate }) {
                          return [
                            _c("sun-input", {
                              attrs: {
                                type: "text",
                                "text-error": _vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                                error: !!_vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.filtersOnSelectFilter(
                                    filter,
                                    $event.value,
                                    onSelect
                                  )
                                },
                                enter: function ($event) {
                                  return _vm.filtersOnSelectFilter(
                                    filter,
                                    $event.value,
                                    onSelect
                                  )
                                },
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: `filter.number`,
                        fn: function ({ filter, onSelect, isDuplicate }) {
                          return [
                            _c("sun-input", {
                              attrs: {
                                type: "text",
                                "text-error": _vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                                error: !!_vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.filtersOnSelectFilter(
                                    filter,
                                    $event.value,
                                    onSelect
                                  )
                                },
                                enter: function ($event) {
                                  return _vm.filtersOnSelectFilter(
                                    filter,
                                    $event.value,
                                    onSelect
                                  )
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("sun-data-table", {
                  staticClass: "w-full",
                  attrs: {
                    headers: _vm.headers,
                    content: _vm.items,
                    hoverable: "",
                    striped: "",
                    loading: _vm.isLoading,
                  },
                  on: { sort: _vm.onSortTable },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: `col.name`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              {
                                class: columnClass,
                                attrs: { title: item.name },
                              },
                              [
                                _c("asterix-column-router-link", {
                                  attrs: {
                                    item: item,
                                    route: _vm.campaignEdit,
                                    "param-value": "id",
                                    "param-name": "campaignId",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.number`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c("sun-data-table-cell", { class: columnClass }, [
                              _vm._v(" " + _vm._s(item.campaignId) + " "),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.status`,
                        fn: function ({ item }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              [
                                _c("status-pill", {
                                  attrs: {
                                    status: item.status.value,
                                    color: item.status.color,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.createdAt`,
                        fn: function ({ item }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              [
                                _c("date-tooltip", {
                                  staticClass: "inline-flex",
                                  attrs: { date: item.createdAt },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.actions`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              { class: columnClass },
                              [
                                _c("table-action-menu", {
                                  attrs: {
                                    actions: _vm.actions,
                                    item: item,
                                    items: _vm.items,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onActionClick($event, item)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "empty",
                        fn: function () {
                          return [
                            _c("asterix-no-data", { staticClass: "bg-white" }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("sun-pagination-page", {
                  key: _vm.currentPage,
                  staticClass: "my-6",
                  attrs: {
                    "total-pages": _vm.totalPages,
                    "total-items": _vm.totalItems,
                    "current-page": _vm.currentPage,
                    value: _vm.itemsPerPage,
                  },
                  on: {
                    changePage: function ($event) {
                      return _vm.goToPage({ page: $event })
                    },
                    changePerPage: _vm.changeItemsPerPage,
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("delete-modal", {
        attrs: { open: _vm.showModal },
        on: { cancel: _vm.closeDeleteModal, confirm: _vm.deleteElement },
        scopedSlots: _vm._u([
          {
            key: "description",
            fn: function () {
              return [_vm._v(" " + _vm._s(_vm.deleteModalDescription) + " ")]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }