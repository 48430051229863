import FILTERS_CONFIG from '@/model/modules/ecommerce/filtersConfig.js';
import Column from '@/model/shared/Column';

export const COLUMNS = {
  NAME: new Column('NAME', 'name', true, true).setClass('max-w-lg truncate font-bold'),
  CAMPAIGN_ID: new Column('CAMPAIGN ID', 'number', true, true).toRight(),
  CLICKS: new Column('CLICKS', 'clicks', true).toRight(),
  STATUS: new Column('STATUS', 'status', true, true),
  CREATED_AT: new Column('CREATED AT', 'createdAt', true),
  ACTIONS: new Column('ACTIONS', 'actions').setClass('w-4'),
};

const CONFIG = {
  columns: [COLUMNS.NAME, COLUMNS.STATUS, COLUMNS.CREATED_AT, COLUMNS.CAMPAIGN_ID, COLUMNS.CLICKS, COLUMNS.ACTIONS],
  filters: [FILTERS_CONFIG.NAME, FILTERS_CONFIG.CAMPAIGN_NUMBER],
};

export default CONFIG;
